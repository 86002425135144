import styled, { css } from "styled-components/macro"
import { Title as TitleComponent } from "features/components"

export const Title = styled(TitleComponent)`
  margin-bottom: 4rem;

  ${({ theme }) => theme.medias.portrait} {
    span {
      font-size: 1.6rem;
    }
  }
  

`

export const TextContainer = styled.div`
  margin-top: -3rem;
  font-size: 2.2rem;
  margin-bottom: 5rem;
`

export const CardContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(400px, 100%), 1fr));
    gap: 40px;

    figure {
      font-family: 'Raleway', Arial, sans-serif;
      position: relative;
      overflow: hidden;
      width: 100%;
      aspect-ratio: 16/9;
      background: ${theme.colors.primary};
      color: #ffffff;
      border-radius: ${({ theme }) => theme.sizes.borderRadius};
      text-align: center;
      box-shadow:
              0.6px 1.3px 2.2px rgba(0, 0, 0, 0.07),
              1.4px 3.2px 5.3px rgba(0, 0, 0, 0.05),
              2.6px 6px 10px rgba(0, 0, 0, 0.042),
              4.7px 10.7px 17.9px rgba(0, 0, 0, 0.035),
              8.8px 20.1px 33.4px rgba(0, 0, 0, 0.028),
              21px 48px 80px rgba(0, 0, 0, 0.02)
    ;
    transition: all .25s ease-in-out;
      
      &.future {
        background: black;
        
        h2 {
          background: #1a1a1a;
        }
        
        &:before {
          background: #808080;
        }
      }


      * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
      }

      figcaption {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      h2 {
        position: absolute;
        left: 40px;
        right: 40px;
        display: inline-block;
        background: ${theme.colors.primaryDark};
        -webkit-transform: skew(-10deg) rotate(-10deg) translate(0, -50%);
        transform: skew(-10deg) rotate(-10deg) translate(0, -50%);
        padding: 12px 5px;
        margin: 0;
        top: 50%;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 2rem;
      }

      h2 span {
        font-weight: 800;
      }

      &:before {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        content: '';
        background: #ffffff;
        position: absolute;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-transform: rotate(110deg) translateY(-50%);
        transform: rotate(110deg) translateY(-50%);
      }

      a {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        z-index: 1;
      }


      img {
        max-width: 100%;
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        opacity: 0.4;
      }
      
      &.available:hover {
        box-shadow:
                0.6px 1.3px 2.2px rgba(0, 0, 0, 0.21),
                1.4px 3.2px 5.3px rgba(0, 0, 0, 0.151),
                2.6px 6px 10px rgba(0, 0, 0, 0.125),
                4.7px 10.7px 17.9px rgba(0, 0, 0, 0.105),
                8.8px 20.1px 33.4px rgba(0, 0, 0, 0.085),
                21px 48px 80px rgba(0, 0, 0, 0.059)
        ;
      }

      &.available:hover img {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
      
      &.available:hover h2 {
        -webkit-transform: skew(-10deg) rotate(-10deg) translate(-150%, -50%);
        transform: skew(-10deg) rotate(-10deg) translate(-150%, -50%);
      }
      
      &.available:hover:before {
        -webkit-transform: rotate(110deg) translateY(-150%);
        transform: rotate(110deg) translateY(-150%);
      }

    }
  `
}`
