import { createGlobalStyle } from "styled-components"

export const AppStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    /* Recalibration of root font-size to make 1rem = 10px, instead of 16px for easier calculation */
    font-size: 62.5%;
  }
  
  body {
    font-family: ${({ theme }) => theme.typography.textFontFamily};
    font-size: 1.6rem;
    background: ${({ theme }) => theme.colors.grey200};
    color: ${({ theme }) => theme.colors.black}
  }
  
  input, button {
    font-family: ${({ theme }) => theme.typography.textFontFamily};
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ theme }) => theme.typography.titleFontFamily};
    line-height: 1.3;
  }
  
  p,span,i {
    font-size: 1.6rem;
  }
  
  h1 {
    font-size: 4rem;
    
    ${({ theme }) => theme.medias.portrait} {
      font-size: 2rem !important;
    }
  }
  
  h2 {
    font-size: 3rem;
  }
  
  h3 {
    font-size: 2rem;
  }
`
