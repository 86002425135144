import styled, { css } from "styled-components/macro"
import { Icon, PrimaryButton, SecondaryButton, Title as TitleComponent } from "features/components"
import { FormControl } from "@material-ui/core"

export const Title = styled(TitleComponent)`
  margin-bottom: 3rem;
  font-size: 3rem;
`

export const ContentContainer = styled.div`
  display: grid;
  width: 100%;
  position: relative;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  ${({ theme }) => theme.medias.portrait} {
    grid-template-columns: 1fr;
  }
`

export const VideoContainer = styled.div``

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const VideoPlayerContainer = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;
  box-shadow: 0.6px 1.3px 2.2px rgba(0, 0, 0, 0.07),
  1.4px 3.2px 5.3px rgba(0, 0, 0, 0.05),
  2.6px 6px 10px rgba(0, 0, 0, 0.042),
  4.7px 10.7px 17.9px rgba(0, 0, 0, 0.035),
  8.8px 20.1px 33.4px rgba(0, 0, 0, 0.028),
  21px 48px 80px rgba(0, 0, 0, 0.02);

  ${({ theme }) => theme.medias.portrait} {
    min-width: 40rem;
  }

  ${({ theme }) => theme.medias.mobile} {
    min-width: 20rem;
  }
`

export const VideoPlayer = styled.video`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: ${theme.sizes.borderRadius};
  `}
`

export const Overlay = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  z-index: 10000;
`

export const Thumbnail = styled.img`
  min-width: 100%;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 1;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  left: 0;
  top: 0;
`

export const PlayIcon = styled(Icon).attrs({
  name: "play_circle_outline",
})`
  font-size: 6rem;
  cursor: pointer;
`

export const Wrapper = styled.div`
  height: auto;
  width: 100%;
  padding: 0 16px 24px 16px;
  box-sizing: border-box;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
  transition: all .25s ease-in;
`
export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;

  &:hover ~ ${RadioButtonLabel} {
    background: #bebebe;

    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #eeeeee;
      transition: all .25s ease-in-out;
    }
  }

  ${(props) =>
    props.checked &&
          ` 
    &:checked + ${RadioButtonLabel} {
      background: #db7290;
      border: 1px solid #db7290;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 12px;
        height: 12px;
      margin: auto;
      position: absolute;
      top: 0; left: 0; bottom: 0; right: 0;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
        background: white;
      }
    }
  `}
`

export const Form = styled.form`
display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const StyledFormControl = styled(FormControl)`
  ${({ theme }) => css`

      width: 100%;
    .MuiFormGroup-root, .MuiFormControl-root {
      width: 100%;
      gap: 2rem;
    }
    
    .MuiFormControlLabel-root {
      padding: 2rem;
      width: 100%;
      margin: 0;
      box-shadow: 0.6px 1.3px 2.2px rgba(0, 0, 0, 0.07),
      1.4px 3.2px 5.3px rgba(0, 0, 0, 0.05),
      2.6px 6px 10px rgba(0, 0, 0, 0.042),
      4.7px 10.7px 17.9px rgba(0, 0, 0, 0.035),
      8.8px 20.1px 33.4px rgba(0, 0, 0, 0.028),
      21px 48px 80px rgba(0, 0, 0, 0.02);
      background: white;
      border-radius: ${({ theme }) => theme.sizes.borderRadius};
      transition: all .25s ease-in-out;
      }

      span {
        padding:0;
        padding-right: 1rem;
      }
    }
    
    .MuiRadio-colorSecondary.Mui-checked {
      color: ${theme.colors.primary};
    }
  `}

`

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 3rem;
  min-width:200px;
  max-width: 300px;
  width: auto;
  display: inline-block;
`

export const StyledSecondaryButton = styled(SecondaryButton)`
  margin-bottom: 3rem;
  min-width:150px;

  color: #1a1a1a;
  width: auto;
  display: inline-block;
`
