import styled from "styled-components/macro"
import { Title as TitleComponent } from "features/components"

export const ErrorPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
`

export const Title = styled(TitleComponent)`
  margin-top: 6rem;
  text-align: center;
`

export const AnimationContainer = styled.div`
  margin-top: 5rem;

  div[aria-label="animation"] {
    max-width: 700px;
    // override lottie element style
    height: 500px !important;
  }
`
