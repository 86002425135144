import React from "react"

import { Component } from "utilities/types"
import * as Styles from "./PageLayout.styles"
import { Header } from "features/components"

export const PageLayout: Component<PageLayoutProps> = ({ companyLogo, cncLogo, title, children })  => {
  return (
    <Styles.Container>
      <Header companyLogo={companyLogo} cncLogo={cncLogo} title={title} />
      <Styles.ContentContainer>
        <Styles.Content>
          {children}
        </Styles.Content>
      </Styles.ContentContainer>
    </Styles.Container>
  )
}

export interface PageLayoutProps {
  companyLogo: string,
  cncLogo: string,
  title: string,
}
