import React from "react"
import {
  ApolloCache,
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  NextLink,
  ObservableSubscription,
  Operation,
  createHttpLink,
} from "@apollo/client"
import { InMemoryCache, NormalizedCacheObject } from "@apollo/client/cache"
import { Observable } from "@apollo/client/utilities"
import { onError } from "@apollo/client/link/error"

import { APP_CONFIG } from "config/AppConfig"

// Create a custom Apollo request link to add custom header and token
export const requestLink = (): ApolloLink => {
  return new ApolloLink((operation: Operation, forward: NextLink) => new Observable(observer => {
    let handle: ObservableSubscription

    Promise.resolve(operation)
      .then(async op => {
        op.setContext({
          headers: {
            "accept": "application/json",
          },
          uri: `${APP_CONFIG.backendGraphql}/public`,
        })
      })
      .then(() => {
        handle = forward(operation).subscribe({
          complete: observer.complete.bind(observer),
          error: observer.error.bind(observer),
          next: observer.next.bind(observer),
        })
      })
      .catch(observer.error.bind(observer))

    return () => {
      if (handle) {
        handle.unsubscribe()
      }
    }
  }))
}

const createBackendProviderLink = (setErrorDialogVisible: (isVisible: boolean) => void): ApolloLink => {
  return ApolloLink.from([
    requestLink(),
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(graphqlError => {
          // eslint-disable-next-line: no-console
          console.error("[GraphQL error]", graphqlError)
        })
      }

      if (networkError) {
        // eslint-disable-next-line: no-console
        console.error("[Network error]", networkError)

        if (
          "statusCode" in networkError
          && [ 401, 403 ].includes(networkError.statusCode)
        ) {
          if (!operation.getContext().shouldNotShowErrorIfUnauthorized) {
            setErrorDialogVisible(true)
          }
        }
      }
      return forward(operation)
    }),
    createHttpLink(),
  ])
}

const createBackendCache = (): ApolloCache<NormalizedCacheObject> => {
  return new InMemoryCache()
}

/**
 * Provide a fully configured client for making GraphQL requests
 * @param props
 */
const BackendProvider: React.FunctionComponent = (props) => {
  const [ isErrorDialogVisible, setErrorDialogVisible ] = React.useState(false)
  const [ apolloClient, setApolloClient ] = React.useState<ApolloClient<NormalizedCacheObject> | undefined>()

  // We use an effect to avoid instantiating the client on each render
  React.useEffect(() => {
    // We build our ApolloClient links chain to process GQL operations
    // https://www.apollographql.com/docs/react/advanced/boost-migration/
    setApolloClient(new ApolloClient({
      cache: createBackendCache(),
      link: createBackendProviderLink(setErrorDialogVisible),
    }))
    // eslint-disable-next-line
  }, [])

  if (!apolloClient) {
    return null
  }
  else {
    return (
      <ApolloProvider client={apolloClient}>
        {/** TODO Create Error dialog */}
        {isErrorDialogVisible && (<p>error</p>)}
        {props.children}
      </ApolloProvider>
    )
  }
}

export {
  BackendProvider,
  createBackendCache,
  createBackendProviderLink,
}
