import ReactMarkdown from "react-markdown"
import styled, { css } from "styled-components/macro"

export const MarkdownContent = styled(ReactMarkdown)`
  ${({ theme }) => css`
    p {
      margin: 1rem 0;
    }
    
    a {
      text-decoration: none;
      color: ${theme.colors.primary};
    }
  `}
`

export const List = styled.ul<ListProps>`
  ${({ theme, $depth, $hasNestedList }) => {
    if ($depth === 0 && $hasNestedList) {
      return css`
        display: grid;
        list-style: none;
        grid-template-columns: repeat(auto-fill,minmax(30rem,1fr));
        width: 100%;
        margin-top: 3rem;
        gap: 3rem;

        > li {
          flex: 1;
          background: white;
          padding: 1.5rem;
          border-radius: ${theme.sizes.borderRadius};
          font-size: ${theme.typography.fontSizeTitle};
          box-shadow: ${theme.shadows.card};
          text-transform: uppercase;
          font-family: ${theme.typography.titleFontFamily};
          font-weight: ${theme.typography.fontWeightSemiBold};
        }
      `
    }
    else {
      return css`
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin-top: 1.6rem;

        li {
          text-transform: initial;
          position: relative;
          font-size: ${theme.typography.fontSizeText};
          font-family: ${theme.typography.textFontFamily};
          font-weight: ${theme.typography.fontWeight};
          padding-left: 2rem;
          padding-top: .3rem;
          padding-bottom: .3rem;
          /* These are technically the same, but use both */
          overflow-wrap: break-word;
          word-wrap: break-word;
          /* Instead use this non-standard one: */
          word-break: break-word;

          /* Adds a hyphen where the word breaks, if supported (No Blink) */
          -ms-hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto;

          &::before {
            content: "";
            position: absolute;
            width: 1rem;
            height: 1rem;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            background: ${theme.colors.primary};
            border-radius: ${theme.sizes.borderRadiusRounded};
          }
        }
      `
    }
  }}
`

interface ListProps {
  $depth: number,
  $hasNestedList: boolean,
}
