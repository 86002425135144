import styled, { css } from "styled-components/macro"
import { motion } from "framer-motion"
import { Icon, PrimaryButton, SecondaryButton, Title as TitleComponent } from "../../../components"

export const Container = styled(motion.div).attrs({
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 50 },
  initial: { opacity: 0, x: -50 },
  transition: { duration: 0.25 },
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const GridContainer = styled.div`
  display: grid;
  width: 100%;
  position: relative;
  grid-template-columns: 1fr 1fr;
  gap: 60px;

  ${({ theme }) => theme.medias.portrait} {
    display: flex;
    flex-direction: column-reverse;
  }

  > div {
    &:first-of-type {
      padding: 4rem;
      width: 100%;
      margin: 0;
      box-shadow: 0.6px 1.3px 2.2px rgb(0 0 0 / 7%),
      1.4px 3.2px 5.3px rgb(0 0 0 / 5%),
      2.6px 6px 10px rgb(0 0 0 / 4%),
      4.7px 10.7px 17.9px rgb(0 0 0 / 4%),
      8.8px 20.1px 33.4px rgb(0 0 0 / 3%),
      21px 48px 80px rgb(0 0 0 / 2%);
      background: white;
      border-radius: 0.5rem;
      -webkit-transition: all .25s ease-in-out;
      transition: all .25s ease-in-out;

      ${({ theme }) => theme.medias.portrait} {
        padding: 2rem;
      }
    }
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`

export const TextContainer = styled.div`
  font-size: 1.6rem;
`

export const Title = styled(TitleComponent)`
  margin-bottom: 2rem;
  color: ${({ theme, size }) => size === "large" ? "#1a1a1a" : theme.colors.primary} 
`

export const StyledPrimaryButton = styled(PrimaryButton)`
  min-width: 200px;
  max-width: 300px;
  width: auto;
  display: inline-block;
`

export const StyledSecondaryButton = styled(SecondaryButton)`
  margin-bottom: 3rem;
  min-width: 150px;

  color: #1a1a1a;
  width: auto;
  display: inline-block;
`

export const FieldSet = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap:2rem;
`

export const Caption = styled.p`
  font-size: 1.4rem;
  font-style: italic;
  margin-bottom: 2rem;
  
  a {
    text-decoration: underline;
    color: #1A1A1A;
  }
`

export const RgpdText = styled.p`
  background-color: ${({ theme }) => theme.colors.grey200};
  padding: 1rem;
  font-size: 1.4rem;
  font-style: italic;
  margin-bottom: 2rem;

  a {
    text-decoration: underline;
    color: #1A1A1A;
  }
  
  overflow: scroll;
  height: 150px;
`

export const Modal = styled(motion.div).attrs({
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 0 },
  initial: { opacity: 0, x: 0 },
  transition: { duration: 0.25 },
})`
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.65);
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalContainer = styled.div`
  max-width: 600px;
  background: white;
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.card};
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem;
  justify-content: center;
  
  svg {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 3rem;
  }
`

export const HomeButton = styled(PrimaryButton)`
  min-width: 300px;
  max-width: 400px;
  width: auto;
  display: inline-block;
`
export const StyledIcon = styled(Icon)<StyledIconProps>`
  display: block;
  font-size: 8rem;
  color: ${({ color }) => color};
  margin-bottom: 2rem;
`

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
`

export const SelectInput = styled.select`
  ${({ theme }) => css`
    height: 5rem;
    border-radius: ${theme.sizes.borderRadiusButton};
    padding: 1.5rem 2.5rem;
    border: 1px solid ${theme.colors.borderDark};
    background: white;
    
    // Input text
    color: ${theme.colors.grey500};
    font-size: ${theme.typography.fontSizeTextMedium};
    
    &::placeholder{
      color: ${theme.colors.grey500};
    }
    
    &:focus-visible {
      outline: ${theme.colors.primary} auto 1px;
    }
  `}
 
`

export const SelectLabel = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeLabel};
    text-transform: uppercase;
  `}
`

export const ThankYouText = styled(TitleComponent)`
  max-width: 70%;
`

export interface StyledIconProps {
  color: string,
}
