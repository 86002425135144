import { PageLayout } from "features/navigation"
import { Component } from "utilities/types"
import * as Styles from "./HomePage.styles"
import { Link, useHistory, useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import React  from "react"
import moment from "moment"
import {
  GetModuleByCompanySlugAndModuleIdDocument,
  GetModuleByCompanySlugAndModuleIdQuery,
} from "../../../graphql/queries/generated/GetModuleByCompanySlugAndModuleId"

export const HomePage: Component = () => {
  moment.locale()
  const history = useHistory()
  const { slug, moduleId } = useParams<{ slug: string, moduleId: string }>()
  const {
    data: moduleData,
  } = useQuery<GetModuleByCompanySlugAndModuleIdQuery>(GetModuleByCompanySlugAndModuleIdDocument, {
    onCompleted(data) {
      if (!data) {
        history.replace("/")
      } else {
        const start = moment(data?.getClapnclickModuleById?.availabilityStart)
        const stop = moment(data?.getClapnclickModuleById?.availabilityStop)
        const isAvailable = moment().isAfter(start) && moment().isBefore(stop)
        if (!isAvailable) {
          history.replace("/")
        }
      }
    },
    variables: {
      slug: slug,
      moduleId: parseInt(moduleId),
    },
  })

  const module = moduleData?.getClapnclickModuleById

  return (
    <>
      {module && (
        <PageLayout
          companyLogo={module.company.companyLogo}
          cncLogo={module.moduleLogo}
          title={module.primaryText}>
          <Styles.Title
            size="extraLarge"
            color="primary"
          >
            Clap'N'Click
          </Styles.Title>
          <Styles.TextContainer
            dangerouslySetInnerHTML={{ __html: module.secondaryText ?? "" }}
          />
          <Styles.CardContainer>
            {module.clapnclickVideos?.map((question) => {
              const startAt = moment(question.availabilityDate)

              if (startAt.isBefore(moment())) {
                return (
                  <Link to={`/${module.company.slug}/${module.id}/${question.id}`}>
                    <figure className={"available"}>
                      <img alt={""} src={question.videoThumbnail}/>
                      <figcaption>
                        <h2>{question.videoName}</h2>
                      </figcaption>
                    </figure>
                  </Link>
                )
              } else {
                return (
                  <figure className={"future"}>
                    <img alt={""} src={question.videoThumbnail}/>
                    <figcaption>
                      <h2>Disponible le {startAt.format("DD/MM/YYYY")}</h2>
                    </figcaption>
                  </figure>
                )
              }
            })}
          </Styles.CardContainer>
        </PageLayout>
      )}
    </>
  )
}
