/* eslint-disable sort-keys */
export const shadows = {
  button: "0 0.3rem 0.6rem rgba(0, 0, 0, 0.16)",
  buttonHover: "0 0.5rem 0.6rem rgba(0, 0, 0, 0.25)",
  card: "0 0.5rem 0.6rem rgba(0, 0, 0, 0.16)",
  main: "0 1rem 3.5rem rgba(0,0,0, 0.37)",
  header: "box-shadow: " +
    "0px 2.1px 2.2px rgba(0, 0, 0, 0.07), " +
    "0px 5px 5.3px rgba(0, 0, 0, 0.05), " +
    "0px 9.4px 10px rgba(0, 0, 0, 0.042), " +
    "0px 16.8px 17.9px rgba(0, 0, 0, 0.035), " +
    "0px 31.3px 33.4px rgba(0, 0, 0, 0.028), " +
    "0px 75px 80px rgba(0, 0, 0, 0.02)",
  // eslint-disable-next-line max-len
  shadowNavColor: "0px 1.2px 1.4px rgba(35, 111, 255, 0.07), 0px 2.9px 3.3px rgba(35, 111, 255, 0.05), 0px 5.5px 6.1px rgba(35, 111, 255, 0.042), 0px 9.8px 10.9px rgba(35, 111, 255, 0.035), 0px 18.4px 20.5px rgba(35, 111, 255, 0.028), 0px 44px 49px rgba(35, 111, 255, 0.02)",
}
