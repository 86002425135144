import * as Styles from "./TextInput.styles"
import React, { ChangeEvent, InputHTMLAttributes } from "react"

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { label, isDisabled, isRequired, ...inputProps } = props
  return <Styles.Container>
    <Styles.Label>{label} {isRequired ? "*" :""}</Styles.Label>
    <Styles.Input
      required={isRequired}
      ref={ref}
      disabled={props.isDisabled}
      {...inputProps}
    />
  </Styles.Container>
})

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  isDisabled?: boolean,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  isRequired?: boolean,
}
