import { clapAndClickFoTheme } from "features/theme/ClapAndClickFoTheme"

export const muiClapAndClickFoVariables = {
  palette: {
    error: {
      main: clapAndClickFoTheme.colors.danger,
    },
    primary: {
      light: clapAndClickFoTheme.colors.primaryLight,
      main: clapAndClickFoTheme.colors.primary,
    },
    secondary: {
      main: clapAndClickFoTheme.colors.secondary,
    },
    tertiary: {
      light: clapAndClickFoTheme.colors.green,
      primary: clapAndClickFoTheme.colors.success,
    },
    text: {
      disabled: clapAndClickFoTheme.colors.grey300,
      light: clapAndClickFoTheme.colors.grey200,
      primary: clapAndClickFoTheme.colors.black,
    },
  },
  sizes: {
    borderRadius: clapAndClickFoTheme.sizes.borderRadius,
    borderRadiusRounded: clapAndClickFoTheme.sizes.borderRadiusRounded,
  },
  typography: {
    fontFamily: clapAndClickFoTheme.typography.textFontFamily,
    fontSize: {
      caption: clapAndClickFoTheme.typography.fontSizeTextExtraSmall,
      text: {
        small: clapAndClickFoTheme.typography.fontSizeTextSmall,
      },
      title: {
        dialog: clapAndClickFoTheme.typography.fontSizeTitle,
      },
    },
  },
}
