import { t } from "ttag"
import { Component } from "utilities/types"
import * as Styles from "./NotFoundPage.styles"
import Lottie, { Options } from "react-lottie"
import animationData from "assets/lotties/notfound.json"

const lottieOptions: Options = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

export const NotFoundPage: Component = () => {
  return (
    <Styles.ErrorPage>
      <Styles.Title
        size="extraLarge"
        color="primary"
        subtitle={"Malheureusement, la page demandée n'existe pas. Vérifiez que l'url est correcte."}
      >
        {t`Page non disponible.`}
      </Styles.Title>
      <Styles.AnimationContainer>
        <Lottie options={lottieOptions}/>
      </Styles.AnimationContainer>
    </Styles.ErrorPage>
  )
}
