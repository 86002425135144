import React from "react"
import { ClapAndClickFoThemeProvider, MuiClapAndClickFoThemeProvider } from "features/theme"
import { BackendProvider } from "features/backend/BackendProvider"

export const Providers: React.FC = ({ children }) => {
  return (
    <BackendProvider>
      <ClapAndClickFoThemeProvider>
        <MuiClapAndClickFoThemeProvider>
          {children}
        </MuiClapAndClickFoThemeProvider>
      </ClapAndClickFoThemeProvider>
    </BackendProvider>
  )
}
