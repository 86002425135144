import * as Styles from "./Header.styles"
import { Component } from "utilities/types"

export const Header: Component<HeaderProps> = ({ companyLogo, cncLogo, title }) => {
  return (
    <Styles.HeaderContainer>
      <Styles.Header>
        <Styles.LogoContainer>
          <img src={companyLogo} alt={"Company Logo"}/>
        </Styles.LogoContainer>
        <Styles.TitleContainer>
          <Styles.StyledTitle dangerouslySetInnerHTML={{ __html: title }}/>
        </Styles.TitleContainer>
        <Styles.LogoContainer>
          <img alt={"clap'n'click Logo"} src={cncLogo}/>
        </Styles.LogoContainer>
      </Styles.Header>
    </Styles.HeaderContainer>
  )
}

export interface HeaderProps {
  companyLogo: string,
  cncLogo: string,
  title: string,
}
