import { motion } from "framer-motion"
import styled, { css } from "styled-components/macro"

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
  `}
`

export const ContentContainer = styled.div<{ $withHomeButton?: boolean, $withNavGrid?: boolean  }>`
  ${({ theme, $withHomeButton, $withNavGrid }) => css`
    width: 100%;
    z-index: 10;
    padding: 100px 40px;
    max-width: 1400px;
    margin: 0 auto;

    ${({ theme }) => theme.medias.portrait} {
      padding: 50px 20px;
    }
  `}
`

export const Content = styled(motion.div).attrs({
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 50 },
  initial: { opacity: 0, x: -50 },
  transition: { duration: 0.25 },
})`
  height: 100%;
  margin: 0 auto;
  position: relative;
`
