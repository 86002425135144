import { createTheme } from "@material-ui/core/styles"

import { muiClapAndClickFoVariables } from "./muiClapAndClickFoVariables"
import { clapAndClickFoTheme } from "../ClapAndClickFoTheme"

export interface MuiClapAndClickFoBreakpointOverrides {
  // Disabled (but might be required by some components of MUI (dialogs))
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  portrait: true,
  reducedLandscape: true,
  landscape: true,
}

export enum zIndexMuiTheme {
  modal = 1300
}

export const muiClapAndClickFoTheme = createTheme({
  typography: {
    htmlFontSize: 10,
  },
  breakpoints: {
    values: {
      portrait: clapAndClickFoTheme.sizes.mobileWidth,
      reducedLandscape: clapAndClickFoTheme.sizes.portraitWidth,
      // eslint-disable-next-line sort-keys
      landscape: clapAndClickFoTheme.sizes.landscapeWidth,
    },
  },
  palette: muiClapAndClickFoVariables.palette,
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
})
