/* tslint:disable */ /* eslint-disable */
import * as Types from '../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AddClapnclickUserOrUserInputMutationVariables = Types.Exact<{
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  email: Types.Scalars['String'];
  moduleId: Types.Scalars['Int'];
  clapnclickUserCustomFields?: Types.InputMaybe<Array<Types.ModuleUserCustomFieldInput> | Types.ModuleUserCustomFieldInput>;
  clapnclickUserVideoChoices?: Types.InputMaybe<Array<Types.ClapnclickUserVideoChoiceInput> | Types.ClapnclickUserVideoChoiceInput>;
}>;


export type AddClapnclickUserOrUserInputMutation = { addClapnclickUserOrUserInput: boolean };


export const AddClapnclickUserOrUserInputDocument = gql`
    mutation AddClapnclickUserOrUserInput($firstName: String!, $lastName: String!, $email: String!, $moduleId: Int!, $clapnclickUserCustomFields: [ModuleUserCustomFieldInput!], $clapnclickUserVideoChoices: [ClapnclickUserVideoChoiceInput!]) {
  addClapnclickUserOrUserInput(
    input: {firstName: $firstName, lastName: $lastName, email: $email, moduleId: $moduleId, clapnclickUserCustomFields: $clapnclickUserCustomFields, clapnclickUserVideoChoices: $clapnclickUserVideoChoices}
  )
}
    `;
export type AddClapnclickUserOrUserInputMutationResult = Apollo.MutationResult<AddClapnclickUserOrUserInputMutation>;
export type AddClapnclickUserOrUserInputMutationOptions = Apollo.BaseMutationOptions<AddClapnclickUserOrUserInputMutation, AddClapnclickUserOrUserInputMutationVariables>;