import { PageLayout } from "features/navigation"
import { Component } from "utilities/types"
import {
  GetModuleByCompanySlugAndModuleIdDocument,
  GetModuleByCompanySlugAndModuleIdQuery,
} from "../../../graphql/queries/generated/GetModuleByCompanySlugAndModuleId"
import * as Styles from "./VideoPage.styles"
import { useHistory, useParams } from "react-router-dom"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "@apollo/client"
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core"
import { ResultPage } from "./ResultPopUp"

export const VideoPage: Component = () => {
  const { register, handleSubmit } = useForm()
  const onSubmit = (data: Record<string, unknown>) => {
    setIsFormSent(true)
    setFormData(data)
  }

  const history = useHistory()
  const [isFormSent, setIsFormSent] = useState(false)
  const [formData, setFormData] = useState<Record<string, unknown>|[]>([])
  const { slug, questionId, moduleId } = useParams<{ slug: string, questionId: string, moduleId: string }>()
  const {
    data: moduleData, loading,
  } = useQuery<GetModuleByCompanySlugAndModuleIdQuery>(GetModuleByCompanySlugAndModuleIdDocument, {
    variables: {
      slug: slug,
      moduleId: parseInt(moduleId),
    },
  })

  const module = moduleData?.getClapnclickModuleById
  const autoStartVideoOnPageLoad = false
  const [isInit, setIsInit] = React.useState(autoStartVideoOnPageLoad)
  const videoPlayerRef = React.useRef<HTMLVideoElement>(null)

  const question = module?.clapnclickVideos?.filter(obj => {
    return obj.id === parseInt(questionId)
  })

  React.useEffect(() => {
    if (autoStartVideoOnPageLoad && videoPlayerRef) {
      setIsInit(true)
    }
  }, [autoStartVideoOnPageLoad, setIsInit, videoPlayerRef])

  if (!loading && !moduleData) {
    history.replace("/")
    return null
  }

  if (question?.length === 0) {
    history.replace(`/${module?.company?.slug}/${module?.id}`)
    return null
  }

  return (
    <>
      {module && question && (
        <PageLayout
          companyLogo={module.company.companyLogo}
          cncLogo={module.moduleLogo}
          title={module?.primaryText}>
          {!isFormSent ? (
            <>
              <Styles.StyledSecondaryButton onClick={history.goBack}>Retour</Styles.StyledSecondaryButton>
              <Styles.Title
                size="extraLarge"
                color="primary"
              >
                {question[0].videoName}
              </Styles.Title>
              <Styles.ContentContainer>
                <Styles.VideoContainer>
                  <Styles.VideoPlayerContainer>
                    {!isInit && (
                      <>
                        <Styles.Thumbnail src={question[0].videoThumbnail}/>
                        <Styles.Overlay onClick={startVideo}>
                          <Styles.PlayIcon/>
                        </Styles.Overlay>
                      </>
                    )}
                    <Styles.VideoPlayer
                      controls
                      ref={videoPlayerRef}
                      autoPlay={autoStartVideoOnPageLoad}
                    >
                      <source src={question[0].video}/>
                      <p>
                        Your browser does no support HTML5 video. You can use this direct link to the video instead.
                        <a
                          href={question[0].video}>
                          {question[0].videoName}
                        </a>
                      </p>
                    </Styles.VideoPlayer>
                  </Styles.VideoPlayerContainer>
                </Styles.VideoContainer>
                <Styles.QuestionContainer>
                  <Styles.Title asHeading={"h2"} size={"large"}>
                    {question[0].videoQuestion}
                  </Styles.Title>
                  <Styles.Form onSubmit={handleSubmit(onSubmit)}>
                    <Styles.StyledFormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                      >
                        {question[0].videoChoices?.map((choice) => {
                          if (choice) {
                            return (
                              <FormControlLabel
                                key={choice.id}
                                {...register(`${question[0].id}`, { required: true })}
                                value={choice?.id?.toString()} control={<Radio/>} label={choice.label}/>
                            )
                          } else {
                            return null
                          }
                        })}
                      </RadioGroup>
                    </Styles.StyledFormControl>
                    <Styles.StyledPrimaryButton
                      disabled={!isInit}
                      isDisabled={!isInit}
                      type="submit">{!isInit ? "Visionnez la vidéo" : "Valider"}
                    </Styles.StyledPrimaryButton>
                  </Styles.Form>
                </Styles.QuestionContainer>
              </Styles.ContentContainer>
            </>
          ) : (
            <ResultPage module={moduleData?.getClapnclickModuleById} video={question} formData={formData}/>
          )}
        </PageLayout>
      )}
    </>
  )

  async function startVideo() {
    const videoPlayer = videoPlayerRef.current
    if (videoPlayer) {
      setIsInit(true)

      if (document.fullscreenEnabled && !autoStartVideoOnPageLoad) {
        try {
          await videoPlayer.requestFullscreen()
        } catch (err) {
          /** Catch the error to allow the question to play
           * even if the browser did not have permission to go fullscreen
           * */
          console.error(err)
        }
      }
      await videoPlayer.play()
    }
  }
}
