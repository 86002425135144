import { Route, Switch } from "react-router-dom"

import { HomePage } from "features/home"
import { NotFoundPage } from "features/notfound"
import { VideoPage } from "features/video"

export const Routes = () => (
  <Switch>
    {/*Features pages */}
    <Route component={HomePage} path="/:slug/:moduleId" exact/>
    <Route component={VideoPage} path="/:slug/:moduleId/:questionId" exact/>

    {/*Errors pages */}
    <Route component={NotFoundPage}/>
  </Switch>
)
