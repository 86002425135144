/* tslint:disable */ /* eslint-disable */
import * as Types from '../../models/generated/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetModuleByCompanySlugAndModuleIdQueryVariables = Types.Exact<{
  slug: Types.Scalars['String'];
  moduleId: Types.Scalars['Int'];
}>;


export type GetModuleByCompanySlugAndModuleIdQuery = { getClapnclickModuleById?: { id: number, settlement: string, availabilityStart: string, availabilityStop: string, primaryText: string, secondaryText?: string | undefined, moduleLogo: string, customFields?: Array<{ id: number, label: string, value: string, fieldType: string, required: boolean }> | undefined, clapnclickVideos?: Array<{ id: number, availabilityDate: string, resultText: string, thankYouText: string, video: string, videoName: string, videoQuestion: string, videoThumbnail: string, videoChoices?: Array<{ id: number, label: string, isValid: boolean }> | undefined }> | undefined, company: { id: number, name: string, companyLogo: string, slug: string, color: string, createdAt: string, updatedAt: string } } | undefined };


export const GetModuleByCompanySlugAndModuleIdDocument = gql`
    query GetModuleByCompanySlugAndModuleId($slug: String!, $moduleId: Int!) {
  getClapnclickModuleById(slug: $slug, moduleId: $moduleId) {
    id
    settlement
    availabilityStart
    availabilityStop
    primaryText
    secondaryText
    moduleLogo
    customFields {
      id
      label
      value
      fieldType
      required
    }
    clapnclickVideos {
      id
      availabilityDate
      resultText
      thankYouText
      video
      videoName
      videoQuestion
      videoThumbnail
      videoChoices {
        id
        label
        isValid
      }
    }
    company {
      id
      name
      companyLogo
      slug
      color
      createdAt
      updatedAt
    }
  }
}
    `;
export type GetModuleByCompanySlugAndModuleIdQueryResult = Apollo.QueryResult<GetModuleByCompanySlugAndModuleIdQuery, GetModuleByCompanySlugAndModuleIdQueryVariables>;